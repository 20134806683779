<template>
  <div class="popup_wrap" style="width:900px;">
    <!-- popup_wrap : style="width:900px; height:600px;" 참고용, 개발시 제거 -->
    <button class="layer_close" @click.prevent="layerClose('X')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">{{ $t('msg.SPEC030.046') }}</h1>
      <form id="frm">
        <div class="content_box">
          <!-- content_box -->
          <table class="tbl_row">
            <colgroup>
              <col width="150px">
              <col width="*">
            </colgroup>
            <tbody>
              <tr>
                <th>
                  {{ $t('msg.SPEC010G010.001') }}    <!-- 구분 -->
                  <span class="position_relative">
                    <button class="tbl_icon help" @click.prevent="helpClick('tip1')">>{{ $t('msg.SPEC010G010.002') }}</button>
                    <div id="tip1" class="tooltip_wrap short position_absolute" style="width:300px; display:none; left:0; top:24px;"><!-- tooltip_wrap   -->
                      <div class="cont"><!-- cont -->
                        <button class="close" @click.prevent="helpClose('tip1')">close</button>
                        <ul class="bul_list_sm t2">
                          <li>
                            {{ $t('msg.SPEC010G010.003') }}<br>
                            {{ $t('msg.SPEC010G010.004') }}
                            <!-- 혼적 : 입력된 품목들을 하나의 컨테이너로 운송<br />
                            단일품목 : 입력된 품목들을 각각의 컨테이너로 운송 -->
                          </li>
                        </ul>
                      </div><!-- cont -->
                    </div><!-- tooltip_wrap // -->
                  </span>
                </th>
                <td>
                  <input type="radio" id="type2" name="rr" v-model="send.asCondCd" value="U">
                  <label for="type2" class="mr20"><span></span>{{ $t('msg.SPEC010G010.006') }}</label>
                  <input type="radio" id="type1" name="type" v-model="send.asCondCd" value="M" @change="checkUM">
                  <label for="type1"><span></span>{{ $t('msg.SPEC010G010.005') }}</label>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.FARE020T010.024') }}</th>
                <td>
                  <select name="" id="cntrTypCd" class="col_3" v-model="send.cntrTypCd" @change="changeCntrTypCd($event)">
                    <option value="">{{ $t('comp.902') }}</option>
                    <option v-for="(item, idx) in containerTypeList" :key="idx" :value="item.cd">{{ item.cd }}</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.CSDO800.004') }}</th> <!-- 출발 -->
                <td>
                  <e-auto-complete-place
                    class="wid300"
                    id="polPol"
                    @change="changePol"
                    :ctr-cd="pol.ctrCd"
                    :plc-cd="pol.plcCd"
                    :is-char="true"
                    :is-char-alert="true"
                  />
                </td>
              </tr>
              <tr>
                <th>{{ $t('msg.CSDO800.005') }}</th> <!-- 도착 -->
                <td>
                  <e-auto-complete-place
                    class="wid300"
                    id="podPod"
                    @change="changePod"
                    :ctr-cd="pod.ctrCd"
                    :plc-cd="pod.plcCd"
                    :is-char="true"
                    :is-char-alert="true"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <table class="tbl_col mt10">
            <colgroup>
              <col width="21%">
              <col width="8%">
              <col width="8%">
              <col width="8%">
              <col width="9%">
              <col width="17%">
              <col width="9%">
              <col width="14%">
              <col width="6%">
            </colgroup>
            <thead>
              <tr>
                <th>UN NO</th>
                <th>CLASS</th>
                <th>SUB RISK 1</th>
                <th>SUB RISK 2</th>
                <th>Packing Group</th>
                <th>PROPER SHIPPING NAME</th>
                <th>SEQ</th>
                <th>MSDS</th>
                <th><button type="button" class="tbl_icon plus" @click.prevent="addRows()">plus</button></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in send.dgInquiryList" :key="index">
                <td>
                  <div class="tbl_form">
                    <span class="dv">
                      <input type="text"
                             :id="'imdunNo' + index"
                             v-model="item.imdunNo"
                             @change.prevent="searchUno(item.imdunNo,index)"
                             @change="changeUnno"
                             disabled
                      >
                      <!-- <button type="button" class="search_btn01"><span class="offscreen"></span></button> -->
                    </span>
                    <span class="dv">
                      <button type="button" class="search_btn01"
                              @click.prevent="openUnnoPopup(index, item.imdunNo)"
                      ><span class="offscreen"></span></button>
                    </span>
                  </div>
                </td>
                <td><input type="text" v-model="item.imoClasCd" readonly></td>
                <td><input type="text" v-model="item.sbRiskVal1" readonly></td>
                <td><input type="text" v-model="item.sbRiskVal2" readonly></td>
                <td><input type="text" v-model="item.pakgGrpNm" readonly></td>
                <td><input type="text" v-model="item.dsc" readonly></td>
                <td>
                  <!-- <select v-if="isPop[index]==='Y'" name="" :value="imdgSeqVal" :disabled="seqFromPop">
                  <option>{{ imdgSeqVal }}</option>
                </select> -->

                  <select :id="'imdgSeq' + index"
                          :disabled="seqFromPop"
                          v-model="item.imdgSeq"
                          @change="changeSeq(item.imdgSeq, index)"
                  >
                    <option v-for="(item2,idx2) in item.seqList" :key="idx2" :value="item2">{{ item2 }}</option>
                  </select>
                </td>
                <td>
                  <msds-file-uploader
                    v-model="item.msdsFileList"
                    :single="false"
                    @completed="uploadCompleted"
                    :row="index"
                    @rtnReqRno="rtnReqRno"
                  />
                </td>
                <!-- [ {{ item.msdsSeq }} ] -->
                <td><button type="button" class="tbl_icon minus" @click.prevent="removeRows(index)">minus</button></td>
              </tr>
            </tbody>
          </table>
          <p class="txt_desc emp">
            {{ $t('msg.SPEC010G010.007') }} <br>
            {{ $t('msg.SPEC010G010.008') }}
            <!-- UNNO 3166, 3171에 한하여 Flat rack 컨테이너로, UNNO 2211, 3314에 한하여 Open top 컨테이너로 운송이 가능합니다.<br />
            위험물의 Reefer 선적은 RF위험물 사전승인 메뉴를 이용해주시기 바랍니다. -->
          </p>
        </div><!-- content_box // -->
      </form>

      <div class="mt10 text_center">
        <a class="button blue lg" @click.prevent="fileSave()">{{ $t('msg.SPEC010G010.009') }}</a>
        <a class="button gray lg" @click.prevent="layerClose('X')">{{ $t('msg.SPEC010G010.010') }}</a>
      </div>
      <div id="layerPopRe">
        <win-layer-pop>
          <component
            :is="customComponent"
            v-if="customComponent"
            :parent-info="parentInfoSch"
            @close="closePopupWrap"
            @selectFunc="selectFunc"
          />
        </win-layer-pop>
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import dangerShipList from '@/api/rest/trans/dangerShipList'
import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import UnnoPop from '@/pages/trans/popup/UnnoPop'/* 스케줄 Unno 검색 팝업 */

export default {
  name: 'DangerShipQstPop',
  components: {
    EAutoCompletePlace,
    WinLayerPop,
    UnnoPop,
    DangerFileUploadPop: () => import('@/pages/trans/popup/DangerFileUploadPop'),
    MsdsFileUploader: () => import('@/pages/trans/popup/MsdsFileUploader')
  },
  props: {
    detail: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data: function () {
    return {
      param: '',
      list: [],
      send: {
        asCondCd: 'U',
        cntrTypCd: '',
        polPortCd: '',
        podPortCd: '',
        dgInquiryList: [{
          imdunNo: '',
          imdgSeq: '',
          imoClasCd: '',
          sbRiskVal1: '',
          sbRiskVal2: '',
          pakgGrpCd: '',
          dsc: '',
          seqList: [],
          dgUnoList: [],
          msdsSeq: '',
          msdsFileList: []
        }],
        arrImSeq: '',
        arrMsdsSeq: '',
        arrUnno: ''
      },
      containerTypeList: [],
      responseReqNo: '',

      pol: {
        ctrCd: '',
        plcCd: ''
      },
      pod: {
        ctrCd: '',
        plcCd: ''
      },
      startPlcCd: '',
      startPlcName: '',
      destPlcCd: '',
      startCtrCd: '',
      destCtrCd: '',
      destPlcName: '',
      startPolName: '',
      destPodName: '',

      // UN NO popup
      selectFunc: null,
      unnoDesc: '',
      customComponent: null,
      idx: '',
      seqFromPop: false,
      imdgSeqVal: [],
      isPop: [],
      fileInfoList: []
    }
  },
  created () {
  },
  mounted: function () {
    const $vm = this
    $vm.searchContainerType()
  },
  methods: {
    closeFun () {
      this.$Simplert.close()
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.SPEC010G010.009'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    closePopupWrap () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('#layerPopRe > .popup_dim')
      return false
    },
    openUnnoPopup (idx, imdunNo) {
      this.seqFromPop = false
      // this.openPopup('UnnoPop')

      // if (!this.$ekmtcCommon.isEmpty(this.uploadParam)) {
      this.customComponent = UnnoPop
      this.parentInfoSch = {
                              scheduleFlag: 'Y',
                              unno: imdunNo
                            }
      this.selectFunc = this.callbackUnno
      this.idx = idx
      this.$ekmtcCommon.layerOpen('#layerPopRe > .popup_dim')
    },
    callbackUnno (obj) {
      let chkDup = true
      // console.log('obj :: ', obj)
      if (obj !== undefined) {
        console.log('@@@@@ callbackUnno @@@@@')

        if (this.send.dgInquiryList.length > 0) {
          this.send.dgInquiryList.forEach((item, idx) => {
            if (item.imdgSeq === obj.imdgSeq && item.imdunNo === obj.imdunNo) {
              chkDup = false
            }
          })
        }

       if (chkDup) {
                 // console.log(obj)
        // TODO: DG(상세검색) 표시부분 확인 >> unno(imdunNo) / class(imoClasCd) / pg(pakgGrpCd)
        this.unnoDesc = obj.imdunNo + '|' + obj.imoClasCd + '|' + obj.pakgGrpCd
        this.send.dgInquiryList[this.idx].imdunNo = obj.imdunNo
        this.send.dgInquiryList[this.idx].imoClasCd = obj.imoClasCd

        this.send.dgInquiryList[this.idx].imdgSeq = obj.imdgSeq
        this.send.dgInquiryList[this.idx].seqList.push(obj.imdgSeq)

        // console.log('this.idx , imdgSeq :: ', this.send.dgInquiryList[this.idx].imdgSeq, this.idx)
        // console.log('this.send.dgInquiryList[this.idx] ', this.send.dgInquiryList[this.idx])

        this.send.dgInquiryList[this.idx].pakgGrpCd = obj.pakgGrpCd
        this.send.dgInquiryList[this.idx].pakgGrpNm = obj.pakgGrpNm
        this.send.dgInquiryList[this.idx].dsc = obj.dsc

        this.seqFromPop = true

        this.changeImdgSeq()
        this.changeUnno()
       } else {
         this.openAlert(this.$t('msg.SPEC010G010.014')) // 중복된 UN No. 입니다.
       }
      } else {
        this.$ekmtcCommon.alertDefault(this.$t('js.common.005'))
      }
    },
    helpClick (tp) {
      $('#' + tp).show()
    },
    helpClose (tp) {
      $('#' + tp).hide()
    },
    changePol (vo) {
      this.pol = vo
      this.startPolName = vo.plcEnm
      this.startCtrCd = vo.ctrCd
      this.startPlcCd = vo.plcCd
      this.startPlcName = vo.plcEnm
      this.send.polPortCd = vo.plcCd
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#polPol')
      // this.$ekmtcCommon.hideErrorTooltip(elem)
      this.localHideErrorTooltip(elem, 'input')
    },
    changePod (vo) {
      this.pod = vo
      this.destPodName = vo.plcEnm
      this.destCtrCd = vo.ctrCd
      this.destPlcCd = vo.plcCd
      this.destPlcName = vo.plcEnm
      this.send.podPortCd = vo.plcCd
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#podPod')
      // this.$ekmtcCommon.hideErrorTooltip(elem)
      this.localHideErrorTooltip(elem, 'input')
    },
    searchContainerType: function (param) {
      dangerShipList.getContainerType().then(response => {
        // console.log('response.data=' + JSON.stringify(response.data))
        this.containerTypeList = response.data.containerTypeList
      }).catch(err => {
        console.log(err)
      })
    },
    checkUM: function () {
      // 혼적인데 리스트가 1개 이면 하나 추가한다.
      if (this.send.dgInquiryList.length < 2) {
        this.addRows()
      }
    },
    addRows: function () {
      //JamSin 단일품목일 경우 하나 초과하여 추가 안되도록...
      if (this.send.asCondCd === 'U') {
        if (this.send.dgInquiryList.length > 0) {
          return
        }
      }
      this.seqFromPop = false

      this.send.dgInquiryList.push({
        imdunNo: '',
        imdgSeq: '',
        imoClasCd: '',
        sbRiskVal1: '',
        sbRiskVal2: '',
        pakgGrpCd: '',
        pakgGrpNm: '',
        dsc: '',
        seqList: [],
        dgUnoList: [],
        msdsSeq: '',
        msdsFileList: []
      })
    },
    removeRows (idx) {
      // 혼적 일 경우 2개 부터는 삭제 안됨 단일품목일 경우 1개부터 삭제 안됨.
      if (this.send.asCondCd === 'M') {
        if (this.send.dgInquiryList.length < 3) {
          return
        }
      } else {
        if (this.send.dgInquiryList.length === 1) {
          return
        }
      }
      this.send.dgInquiryList.splice(idx, 1)
    },
    searchUno: function (item, idx) {
      this.seqFromPop = false

      this.send.dgInquiryList[idx].seqList = []
      dangerShipList.getUno(item).then(response => {
        // console.log(JSON.stringify(response.data))

        if (response.data.dgList.length > 0) {
          this.send.dgInquiryList[idx].dgUnoList = response.data.dgList
          response.data.dgList.forEach((itm, ix) => {
            // this.send.dgInquiryList[idx].seqList.push(ix + 1)
            this.send.dgInquiryList[idx].seqList.push(itm.imdgSeq)
          })
        } else {
          this.send.dgInquiryList[idx].imoClasCd = ''
          this.send.dgInquiryList[idx].dsc = ''
          this.send.dgInquiryList[idx].pakgGrpCd = ''
        }

        // console.log(this.send.dgInquiryList[0].seqList)
        // console.log(this.send.dgInquiryList[0])
        // console.log('response.data.dgList ::: ', response.data.dgList.length)
      }).catch(err => {
        console.log(err)
      })
    },
    changeSeq: function (seq, idx) {
      // this.send.dgInquiryList[idx].imdunNo = this.send.dgInquiryList[idx].dgUnoList[seq - 1].imdunNo
      this.send.dgInquiryList[idx].imoClasCd = this.send.dgInquiryList[idx].dgUnoList[seq - 1].imoClasCd
      this.send.dgInquiryList[idx].dsc = this.send.dgInquiryList[idx].dgUnoList[seq - 1].dsc
      this.send.dgInquiryList[idx].pakgGrpCd = this.send.dgInquiryList[idx].dgUnoList[seq - 1].pakgGrpCd

      this.changeImdgSeq()
    },
    fileSave: function () {
      if (this.fnFormValidate()) {
        let fileList = []
        for (let fileInfo of this.fileInfoList) {
          for (let file of fileInfo.fileInfo) {
            file.fileSt = file.new
            fileList.push(file)
          }
        }
        dangerShipList.fileSave({ as_req_no: null, file_info: fileList }).then(response => {
          console.log('response.data=' + JSON.stringify(response.data))
          //this.req_rno = response.data
          this.save(response.data)
        }).catch(err => {
          console.log(err)
        })
      }
    },
    save: function (reqRno) {
      this.send.dgInquiryList.forEach((item, idx) => {
        this.send.arrImSeq += (this.send.dgInquiryList.length === (idx + 1)) ? item.imdgSeq : item.imdgSeq + ';'
        this.send.arrMsdsSeq += (this.send.dgInquiryList.length === (idx + 1)) ? reqRno : reqRno + ';'
        this.send.arrUnno += (this.send.dgInquiryList.length === (idx + 1)) ? item.imdunNo : item.imdunNo + ';'
      })
      console.log(this.send)
      dangerShipList.saveDg(this.send).then(response => {
        console.log(JSON.stringify(response.data))
        this.responseReqNo = response.data.reqNo
        this.openAlert(this.$t('msg.SPEC010G010.011'))
        this.layerClose(this.responseReqNo)
      }).catch(err => {
        console.log(err)
      })
    },
    LocalShowErrorTooltip (elem, message, flag) {
          const $elem = $(elem)
          const $parent = $elem.parents('td, div').eq(0)
          let $input = ''
          let $sParent = ''
          let $sMsgBox = ''
          let $msgBox = $parent.find('.tooltip_essen_box')

          if (flag === 'input') {
            $input = $elem.children('div').children('input').eq(0)
            $input.addClass('input_error')
          } else {
            $elem.addClass('input_error')
          }

          if (flag === 'imdunNo') {
            $sParent = $parent.parents('td').eq(0)
            $sParent.addClass('position_relative')
            $sMsgBox = $sParent.find('.tooltip_essen_box')

            if ($sMsgBox.length === 0) {
             $msgBox = $('<p class=\'tooltip_essen_box\'><span class=\'tooltip_essen\'></span></p> ')
             $msgBox.appendTo($sParent)
            }
          } else {
            $parent.addClass('position_relative')

            if ($msgBox.length === 0) {
              $msgBox = $('<p class=\'tooltip_essen_box\'><span class=\'tooltip_essen\'></span></p> ')
              $msgBox.appendTo($parent)
            }
          }

          $msgBox.find('.tooltip_essen').eq(0).text(message).show()
    },
    localHideErrorTooltip (elem, flag) {
          const $elem = $(elem)
          const $parent = $elem.parents('td, div').eq(0)
          let $input = ''
          let $sParent = ''

          if (flag === 'input') {
            $input = $elem.children('div').children('input').eq(0)
            $input.removeClass('input_error')
          }

          if (flag === 'imdunNo') {
            $sParent = $parent.parents('td').eq(0).addClass('position_relative')
            $sParent.find('.tooltip_essen').hide()
          }

          $elem.removeClass('input_error')
          $parent.find('.tooltip_essen').hide()
    },
    changeCntrTypCd () {
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#cntrTypCd')
      this.localHideErrorTooltip(elem)
    },
    changeUnno () {
      this.send.dgInquiryList.forEach((item, idx) => {
          this.localHideErrorTooltip(document.querySelector('#imdunNo' + idx), 'imdunNo')
      })
    },
    changeImdgSeq () {
      this.send.dgInquiryList.forEach((item, idx) => {
          this.localHideErrorTooltip(document.querySelector('#imdgSeq' + idx))
      })
    },
    fnFormValidate: function () {
      const frm = document.querySelector('#frm')
      var elem = ''

      let isOk = true
      let msg = ''
      let flag = ''

      if (this.send.cntrTypCd === '') {
        msg = 'Please Input Container Type.'
        elem = frm.querySelector('#cntrTypCd')
        // this.$ekmtcCommon.showErrorTooltip(elem, msg)
        this.LocalShowErrorTooltip(elem, msg)
        return false
      }
      if (this.send.polPortCd === '') {
        msg = 'Please Input POL Code.'
        elem = frm.querySelector('#polPol')
        flag = 'input'

        this.LocalShowErrorTooltip(elem, msg, flag)
        return false
      }
      if (this.send.podPortCd === '') {
        msg = 'Please Input POD Code.'
        elem = frm.querySelector('#podPod')
        flag = 'input'

        this.LocalShowErrorTooltip(elem, msg, flag)
        return false
      }

      //JamSin 단일품목일 경우 하나만 등록 가능.
      if (this.send.asCondCd === 'U') {
        if (this.send.dgInquiryList.length > 1) {
          this.openAlert(this.$t('msg.SPEC010G010.015'))
          return false
        }
      }

      this.send.dgInquiryList.forEach((item, idx) => {
        if (this.send.dgInquiryList[idx].imdunNo === '') {
          this.LocalShowErrorTooltip(document.querySelector('#imdunNo' + idx), 'Please Input UN NO.', 'imdunNo')
          isOk = false
        }
        if (this.send.dgInquiryList[idx].imdgSeq === '') {
          this.LocalShowErrorTooltip(document.querySelector('#imdgSeq' + idx), 'Please Input SEQ NO.')
          isOk = false
        }
      })
      return isOk
    },
    layerClose: function (responseReqNo) {
      //if (responseReqNo !== '') {
        //alert(responseReqNo)
        this.$emit('close', responseReqNo)
      //} else {
        //this.$emit('close', 'X')
      //}
      return false
    },
    uploadCompleted: function (data) {
      //console.log('file.index===' + idx)
      console.log(data)
      let existIdx = null
      for (let i = 0; i < this.fileInfoList.length; i++) {
        if (data.componentId === this.fileInfoList[i].componentId) {
          existIdx = i
          break
        }
      }
      if (existIdx !== null) {
        this.fileInfoList[existIdx].fileInfo = data.fileInfo
      } else {
        this.fileInfoList.push({ fileInfo: data.fileInfo, componentId: data.componentId })
      }
    },
    rtnReqRno: function (req_rno, row) {
      this.send.dgInquiryList[row].msdsSeq = req_rno
      console.log('req_rno ::: ', this.send.dgInquiryList[row].msdsSeq)
    }
  }
}
</script>
<style scoped>
.popup_wrap{
  -ms-overflow-style: none;
}
.popup_wrap::-webkit-scrollbar{
  display:none;
}

</style>
