var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "900px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.layerClose("X")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.SPEC030.046"))),
        ]),
        _c("form", { attrs: { id: "frm" } }, [
          _c("div", { staticClass: "content_box" }, [
            _c("table", { staticClass: "tbl_row" }, [
              _vm._m(0),
              _c("tbody", [
                _c("tr", [
                  _c("th", [
                    _vm._v(" " + _vm._s(_vm.$t("msg.SPEC010G010.001")) + " "),
                    _c("span", { staticClass: "position_relative" }, [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon help",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.helpClick("tip1")
                            },
                          },
                        },
                        [_vm._v(">" + _vm._s(_vm.$t("msg.SPEC010G010.002")))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tooltip_wrap short position_absolute",
                          staticStyle: {
                            width: "300px",
                            display: "none",
                            left: "0",
                            top: "24px",
                          },
                          attrs: { id: "tip1" },
                        },
                        [
                          _c("div", { staticClass: "cont" }, [
                            _c(
                              "button",
                              {
                                staticClass: "close",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.helpClose("tip1")
                                  },
                                },
                              },
                              [_vm._v("close")]
                            ),
                            _c("ul", { staticClass: "bul_list_sm t2" }, [
                              _c("li", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("msg.SPEC010G010.003"))
                                ),
                                _c("br"),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("msg.SPEC010G010.004")) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.send.asCondCd,
                          expression: "send.asCondCd",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "type2",
                        name: "rr",
                        value: "U",
                      },
                      domProps: { checked: _vm._q(_vm.send.asCondCd, "U") },
                      on: {
                        change: function ($event) {
                          return _vm.$set(_vm.send, "asCondCd", "U")
                        },
                      },
                    }),
                    _c(
                      "label",
                      { staticClass: "mr20", attrs: { for: "type2" } },
                      [
                        _c("span"),
                        _vm._v(_vm._s(_vm.$t("msg.SPEC010G010.006"))),
                      ]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.send.asCondCd,
                          expression: "send.asCondCd",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "type1",
                        name: "type",
                        value: "M",
                      },
                      domProps: { checked: _vm._q(_vm.send.asCondCd, "M") },
                      on: {
                        change: [
                          function ($event) {
                            return _vm.$set(_vm.send, "asCondCd", "M")
                          },
                          _vm.checkUM,
                        ],
                      },
                    }),
                    _c("label", { attrs: { for: "type1" } }, [
                      _c("span"),
                      _vm._v(_vm._s(_vm.$t("msg.SPEC010G010.005"))),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.FARE020T010.024")))]),
                  _c("td", [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.send.cntrTypCd,
                            expression: "send.cntrTypCd",
                          },
                        ],
                        staticClass: "col_3",
                        attrs: { name: "", id: "cntrTypCd" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.send,
                                "cntrTypCd",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                            function ($event) {
                              return _vm.changeCntrTypCd($event)
                            },
                          ],
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v(_vm._s(_vm.$t("comp.902"))),
                        ]),
                        _vm._l(_vm.containerTypeList, function (item, idx) {
                          return _c(
                            "option",
                            { key: idx, domProps: { value: item.cd } },
                            [_vm._v(_vm._s(item.cd))]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSDO800.004")))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("e-auto-complete-place", {
                        staticClass: "wid300",
                        attrs: {
                          id: "polPol",
                          "ctr-cd": _vm.pol.ctrCd,
                          "plc-cd": _vm.pol.plcCd,
                          "is-char": true,
                          "is-char-alert": true,
                        },
                        on: { change: _vm.changePol },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSDO800.005")))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("e-auto-complete-place", {
                        staticClass: "wid300",
                        attrs: {
                          id: "podPod",
                          "ctr-cd": _vm.pod.ctrCd,
                          "plc-cd": _vm.pod.plcCd,
                          "is-char": true,
                          "is-char-alert": true,
                        },
                        on: { change: _vm.changePod },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _c("table", { staticClass: "tbl_col mt10" }, [
              _vm._m(1),
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v("UN NO")]),
                  _c("th", [_vm._v("CLASS")]),
                  _c("th", [_vm._v("SUB RISK 1")]),
                  _c("th", [_vm._v("SUB RISK 2")]),
                  _c("th", [_vm._v("Packing Group")]),
                  _c("th", [_vm._v("PROPER SHIPPING NAME")]),
                  _c("th", [_vm._v("SEQ")]),
                  _c("th", [_vm._v("MSDS")]),
                  _c("th", [
                    _c(
                      "button",
                      {
                        staticClass: "tbl_icon plus",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.addRows()
                          },
                        },
                      },
                      [_vm._v("plus")]
                    ),
                  ]),
                ]),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.send.dgInquiryList, function (item, index) {
                  return _c("tr", { key: index }, [
                    _c("td", [
                      _c("div", { staticClass: "tbl_form" }, [
                        _c("span", { staticClass: "dv" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.imdunNo,
                                expression: "item.imdunNo",
                              },
                            ],
                            attrs: {
                              type: "text",
                              id: "imdunNo" + index,
                              disabled: "",
                            },
                            domProps: { value: item.imdunNo },
                            on: {
                              change: [
                                function ($event) {
                                  $event.preventDefault()
                                  return _vm.searchUno(item.imdunNo, index)
                                },
                                _vm.changeUnno,
                              ],
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(item, "imdunNo", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("span", { staticClass: "dv" }, [
                          _c(
                            "button",
                            {
                              staticClass: "search_btn01",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.openUnnoPopup(index, item.imdunNo)
                                },
                              },
                            },
                            [_c("span", { staticClass: "offscreen" })]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.imoClasCd,
                            expression: "item.imoClasCd",
                          },
                        ],
                        attrs: { type: "text", readonly: "" },
                        domProps: { value: item.imoClasCd },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(item, "imoClasCd", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.sbRiskVal1,
                            expression: "item.sbRiskVal1",
                          },
                        ],
                        attrs: { type: "text", readonly: "" },
                        domProps: { value: item.sbRiskVal1 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(item, "sbRiskVal1", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.sbRiskVal2,
                            expression: "item.sbRiskVal2",
                          },
                        ],
                        attrs: { type: "text", readonly: "" },
                        domProps: { value: item.sbRiskVal2 },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(item, "sbRiskVal2", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.pakgGrpNm,
                            expression: "item.pakgGrpNm",
                          },
                        ],
                        attrs: { type: "text", readonly: "" },
                        domProps: { value: item.pakgGrpNm },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(item, "pakgGrpNm", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: item.dsc,
                            expression: "item.dsc",
                          },
                        ],
                        attrs: { type: "text", readonly: "" },
                        domProps: { value: item.dsc },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(item, "dsc", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: item.imdgSeq,
                              expression: "item.imdgSeq",
                            },
                          ],
                          attrs: {
                            id: "imdgSeq" + index,
                            disabled: _vm.seqFromPop,
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  item,
                                  "imdgSeq",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.changeSeq(item.imdgSeq, index)
                              },
                            ],
                          },
                        },
                        _vm._l(item.seqList, function (item2, idx2) {
                          return _c(
                            "option",
                            { key: idx2, domProps: { value: item2 } },
                            [_vm._v(_vm._s(item2))]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c(
                      "td",
                      [
                        _c("msds-file-uploader", {
                          attrs: { single: false, row: index },
                          on: {
                            completed: _vm.uploadCompleted,
                            rtnReqRno: _vm.rtnReqRno,
                          },
                          model: {
                            value: item.msdsFileList,
                            callback: function ($$v) {
                              _vm.$set(item, "msdsFileList", $$v)
                            },
                            expression: "item.msdsFileList",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("td", [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon minus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.removeRows(index)
                            },
                          },
                        },
                        [_vm._v("minus")]
                      ),
                    ]),
                  ])
                }),
                0
              ),
            ]),
            _c("p", { staticClass: "txt_desc emp" }, [
              _vm._v(" " + _vm._s(_vm.$t("msg.SPEC010G010.007")) + " "),
              _c("br"),
              _vm._v(" " + _vm._s(_vm.$t("msg.SPEC010G010.008")) + " "),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.fileSave()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.SPEC010G010.009")))]
          ),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.layerClose("X")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.SPEC010G010.010")))]
          ),
        ]),
        _c(
          "div",
          { attrs: { id: "layerPopRe" } },
          [
            _c(
              "win-layer-pop",
              [
                _vm.customComponent
                  ? _c(_vm.customComponent, {
                      tag: "component",
                      attrs: { "parent-info": _vm.parentInfoSch },
                      on: {
                        close: _vm.closePopupWrap,
                        selectFunc: _vm.selectFunc,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "150px" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "21%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "17%" } }),
      _c("col", { attrs: { width: "9%" } }),
      _c("col", { attrs: { width: "14%" } }),
      _c("col", { attrs: { width: "6%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }